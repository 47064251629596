import { render, staticRenderFns } from "./LoginSecond.vue?vue&type=template&id=572c6c55&scoped=true&"
import script from "./LoginSecond.vue?vue&type=script&lang=js&"
export * from "./LoginSecond.vue?vue&type=script&lang=js&"
import style0 from "./LoginSecond.vue?vue&type=style&index=0&id=572c6c55&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "572c6c55",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VCol,VIcon,VImg,VRow,VTextField})
